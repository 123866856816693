<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-1-2@s font-header">
        {{ headerTitle }}
      </div>
    </div>

    <container />
    <div id="container-modal-delete" />
    <modal-delete-confirm />
  </div>
</template>

<script>
import getHeaderTitle from '@/utils/header-title'
import Container from './container'
import { dateString } from '@/utils/formater'
import ModalDeleteConfirm from './ModalDeleteConfirm'

export default {
  components: {
    Container,
    ModalDeleteConfirm
  },
  computed: {
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  methods: {
    getDatetime(date) {
      return dateString(date)
    }
  }
}
</script>
