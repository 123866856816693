<template>
  <div>
    <div class="filter-container">
      <div class="uk-form-horizontal">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label filter-margin"
              for="search"
            >Cari</label>
            <div class="uk-form-controls">
              <input
                id="search-name"
                v-model="metaFilter.name"
                class="uk-input"
                type="text"
                placeholder="Cari kode siklus / nama kandang..."
              >
            </div>
          </div>
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="cycle_count"
            >
              Siklus Ke
            </label>
            <div class="uk-form-controls">
              <input
                id="search-cycle"
                v-model="metaFilter.cycle_count"
                class="uk-input"
                type="text"
                placeholder="Semua"
              >
            </div>
          </div>
        </div>
        <div
          class="filter-margin uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label filter-margin"
              for="cage_staff"
            >Anak Kandang</label>
            <div class="uk-form-controls">
              <input
                id="cage_staff"
                v-model="metaFilter.cage_staff"
                class="uk-input"
                type="text"
                placeholder="Cari anak kandang..."
              >
            </div>
          </div>

          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="population"
            >Populasi (ekor)</label>
            <div class="uk-form-controls">
              <div
                uk-grid
                class="uk-grid-small"
              >
                <div class="uk-width-2-5@s">
                  <input
                    id="search-population-min"
                    v-model="metaFilter.population_start"
                    class="uk-input"
                    type="text"
                    placeholder="0"
                  >
                </div>
                <div class="uk-width-1-5@s uk-text-center filter-padding">
                  to
                </div>
                <div class="uk-width-2-5@s">
                  <input
                    id="search-population-max"
                    v-model="metaFilter.population_end"
                    class="uk-input"
                    type="text"
                    placeholder="0"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="filter-margin uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label filter-margin"
              for="field_spv"
            >Pengawas Lapangan</label>
            <div class="uk-form-controls">
              <input
                id="field_spv"
                v-model="metaFilter.field_spv"
                class="uk-input"
                type="text"
                placeholder="Cari pengawas lapangan..."
              >
            </div>
          </div>

          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="bobot"
            >Bobot Sampel (gr)</label>
            <div class="uk-form-controls">
              <div
                uk-grid
                class="uk-grid-small"
              >
                <div class="uk-width-2-5@s">
                  <input
                    id="search-weight-min"
                    v-model="metaFilter.weight_start"
                    class="uk-input"
                    type="text"
                    placeholder="0"
                  >
                </div>
                <div class="uk-width-1-5@s uk-text-center filter-padding">
                  to
                </div>
                <div class="uk-width-2-5@s">
                  <input
                    id="search-weight-max"
                    v-model="metaFilter.weight_end"
                    class="uk-input"
                    type="text"
                    placeholder="0"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="filter-margin uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l first-column">
            <label
              class="uk-form-label filter-margin"
              for="tahapan"
            >
              Tahapan
            </label>
            <div class="uk-form-controls">
              <multiselect
                id="cage-category-type" 
                v-model="cageCategoryChoose" 
                label="name"
                name="cage-category-type" 
                track-by="name" 
                placeholder="Pilih Tipe Kandang" 
                open-direction="bottom" 
                :options="cageCategoryFilters" 
                :searchable="true" 
                :loading="isLoading"
                :close-on-select="true" 
                :max-height="500" 
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <multiselect 
                v-if="cageCategoryChoose" 
                id="cage-category-stages" 
                v-model="activityChoose" 
                label="name"
                track-by="name" 
                placeholder="Semua" 
                open-direction="bottom" 
                :options="activityFilters" 
                :searchable="true" 
                :loading="isLoading"
                :close-on-select="true" 
                :max-height="500" 
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <!-- <select class="uk-input uk-select"
                  name="tahapan"
                  v-model="metaFilter.tahapan" 
                  id="tahapan">
                  <option value="">Semua</option>
                </select> -->
            </div>
          </div>

          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="feed"
            >Stok Pakan (sak)</label>
            <div class="uk-form-controls">
              <div
                uk-grid
                class="uk-grid-small"
              >
                <div class="uk-width-2-5@s">
                  <input
                    id="search-stock-min"
                    v-model="metaFilter.food_stock_start"
                    class="uk-input"
                    type="text"
                    placeholder="0"
                  >
                </div>
                <div class="uk-width-1-5@s uk-text-center filter-padding">
                  to
                </div>
                <div class="uk-width-2-5@s">
                  <input
                    id="search-stock-max"
                    v-model="metaFilter.food_stock_end"
                    class="uk-input"
                    type="text"
                    placeholder="0"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="filter-margin uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l uk-text-right">
            <button
              class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
              @click="clearFilter()"
            >
              <span
                uk-icon="icon: close; ratio: 0.9"
                class="uk-margin-small-right"
              />Clear
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              @click="handleFind()"
            >
              <span
                uk-icon="icon: search; ratio: 0.9"
                class="uk-margin-small-right"
              />Cari
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="uk-child-width-expand@s uk-grid-small uk-margin-top"
      uk-grid
    >
      <div class="uk-width-1-1 uk-width-1-2@s uk-width-1-2@m uk-width-1-4@l">
        <button
          v-if="isCanAccessUser(`add`, `Kegiatan Kandang`) && isStaging"
          class="uk-button uk-button-default uk-width-expand uk-width-auto@s uk-margin-right"
          @click="toImport()"
        >
          <span
            uk-icon="icon: upload; ratio: 0.7"
            class="uk-margin-small-right"
          />Import
        </button>
        <router-link
          v-if="isCanAccessUser(`add`,`kegiatan kandang`)"
          class="uk-button uk-button-default"
          to="/admin/kegiatan-kandang/add"
        >
          <span
            uk-icon="icon: plus; ratio: 0.7"
            class="uk-margin-small-right"
          />Tambah Kegiatan
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { dateString } from '@/utils/formater'
import { isCanAccess } from '@/utils/auth'

export default {
  data() {
    return {
      isStaging: process.env.VUE_APP_ENV == 'staging',
      isLoading: false,
      cageCategoryChoose: '',
      cageCategoryFilters: [],
      metaFilter: {
        name: '',
        tahapan: '',
        cage_staff: '',
        field_spv: '',
        cycle_count: '',
        population_start: '',
        population_end: '',
        weight_start: '',
        weight_end: '',
        food_stock_start: '',
        food_stock_end: ''
      },
      metaFilterActivity: {
        name: ''
      },
      activityChoose: '',
      activityFilters: []
    }
  },
  computed : {
    ...mapGetters({
      metaActivity: 'activity/meta',
      activities: 'activity/activities',
      cageCategorys: 'cageCategory/cageCategorys'
    })
  },
  watch: {
    async cageCategoryChoose(val){
      this.activityChoose = null
      await this.getActivity({ cage_category_id: val.id, is_all: true})
      this.activityFilters = this.activities ? this.activities : []
    },
    'metaFilter.created_start'() {
      this.metaFilter.created_start = this.dateFormatFilter(this.metaFilter.created_start)
    },
    'metaFilter.created_end'() {
      this.metaFilter.created_end = this.dateFormatFilter(this.metaFilter.created_end)
    },
    'metaFilter.updated_start'() {
      this.metaFilter.updated_start = this.dateFormatFilter(this.metaFilter.updated_start)
    },
    'metaFilter.updated_end'() {
      this.metaFilter.updated_end = this.dateFormatFilter(this.metaFilter.updated_end)
    }
  },
  async mounted() {
    await this.getCageCategory({is_all: true, status:true})
    this.cageCategoryFilters = this.cageCategorys ? this.cageCategorys : []
  },
  methods: {
    toImport(){
      this.$router.push(`/admin/kegiatan-kandang/import`)
    },
    dateFormatFilter(date) {
      return dateString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getActivity: 'activity/getActivity',
      getCageCategory: 'cageCategory/getCageCategory'
    }),
    ...mapMutations({
      setMeta: 'activityCage/SET_META'
    }),
    async handleFind () {
      this.metaFilter.page = 1
      this.metaFilter.tahapan = this.activityChoose ? this.activityChoose.name : ''
      this.setMeta({
        ...this.meta,
        ...this.metaFilter
      })
    },
    async toFormAdd () {
      window.location.href = `/admin/kegiatan-kandang/add`
    },
    async clearFilter() {
      const app = this
      app.activityChoose = ''
      app.metaFilter.name = ''
      app.metaFilter.cage_staff = ''
      app.metaFilter.field_spv = ''
      app.metaFilter.tahapan = ''
      app.metaFilter.cycle_count = ''
      app.metaFilter.population_start = ''
      app.metaFilter.population_end = ''
      app.metaFilter.weight_start = ''
      app.metaFilter.weight_end = ''
      app.metaFilter.food_stock_start = ''
      app.metaFilter.food_stock_end = ''
      await app.handleFind()
    }
  }
}
</script>
