<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-divider">
        <TableHeader />
        <template v-if="!is_loading">
          <tbody v-if="activityCages.meta.total_count > 0">
            <tr
              v-for="(cage, i) in activityCages.data"
              :key="i"
            >
              <td>{{ cage.cycle_code || "-" }}</td>
              <td>{{ cage.name || "-" }}</td>
              <td>{{ cage.cage_staff || "-" }}</td>
              <td>{{ cage.field_supervisor || "-" }}</td>
              <td>{{ cage.total_cycle || 0 }}</td>
              <td>{{ cage.current_log_activity || "-" }}</td>
              <td>{{ formatDate(cage.chick_in) || "-" }}</td>
              <td>{{ cage.population || 0 }}</td>
              <td>{{ formatDate(cage.created_at) || "-" }}</td>
              <td>{{ formatDate(cage.updated_at) || "-" }}</td>
              <td>{{ cage.current_dead_chicken || 0 }}</td>
              <td>{{ cage.current_weight_sample || 0 }}</td>
              <td>{{ cage.current_food_stock || 0 }}</td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-if="isCanAccessUser(`delete`, `Kegiatan Kandang`)"
                  v-lazy="`${images}/icon/trash.svg`"
                  alt=""
                  class="uk-margin-small-right img-click"
                  @click="showDeleteConfirmModal(cage)"
                >
                <img
                  v-if="isCanAccessUser(`edit`, `Kegiatan Kandang`)"
                  v-lazy="`${images}/icon/edit-black.svg`"
                  alt=""
                  class="img-click"
                  @click="showEdit(cage.id)"
                >
                <img
                  v-if="isCanAccessUser(`view`, `Kegiatan Kandang`)"
                  v-lazy="`${images}/icon/eye.svg`"
                  alt=""
                  class="uk-margin-small-left img-click"
                  @click="showDetail(cage.id)"
                >
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="11"
          />
        </template>
        <template v-else>
          <loading-table :colspan="11" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="activityCages.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Pagination from "@/components/globals/Pagination"
import EmptyTable from "@/components/globals/table/EmptyTable"
import LoadingTable from "@/components/globals/table/LoadingTable"
import { STATUS } from "@/utils/constant"
import { dateUtcParanjeString } from "@/utils/formater"
import { PREFIX_IMAGE } from "@/utils/constant"
import { isCanAccess } from "@/utils/auth"
import TableHeader from "./tableHeader"

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination,
    TableHeader
  },
  data() {
    return {
      is_loading: true,
      status: STATUS,
      images: PREFIX_IMAGE,
      sortCode: "updateDown"
    }
  },
  computed: {
    ...mapGetters({
      activityCages: "activityCage/activityCages",
      meta: "activityCage/meta"
    })
  },
  watch: {
    async meta() {
      this.is_loading = true
      await this.getActivityCage(this.meta)
      this.is_loading = false
    }
  },
  async mounted() {
    this.setMeta({
      ...this.meta,
      ["order_by"]: "updated_at",
      ["sort_by"]: "DESC"
    })
    await this.getActivityCage(this.meta)
    this.is_loading = false
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getActivityCage: "activityCage/getActivityCage"
    }),
    ...mapMutations({
      setMeta: "activityCage/SET_META",
      setModalDelete: "activityCage/SET_MODAL_DELETE"
    }),
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    },
    showDetail(id) {
      this.$router.push(`/admin/kegiatan-kandang/detail/${id}`)
    },
    showEdit(id) {
      this.$router.push(`/admin/kegiatan-kandang/edit/${id}`)
    },
    showDeleteConfirmModal(detailSop) {
      window.UIkit.modal("#modal-delete-confirm").show()
      this.setModalDelete(detailSop)
    },
    changeSort(field, fieldValue, sortDirection, sortDirectionValue) {
      this.setMeta({
        ...this.meta,
        [field]: fieldValue,
        [sortDirection]: sortDirectionValue
      })
    },
    sortSwitch(a) {
      this.sortCode = a
    }
  }
}
</script>
