<template>
  <thead class="thead-table-paranje">
    <tr>
      <th class="uk-table-expand">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-text-bold uk-width-expand">Kode Siklus</span>
          <div class="uk-float-right uk-flex-column uk-width-auto">
            <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
              <div v-if="sortCode == 'cycleCodeDown'">
                <div class="sort-icon">
                  <img
                    :src="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('cycleCodeUp'), changeSort('order_by', 'cycle_code', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    :src="`${images}/icon/VectordownInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
              </div>
              <div v-else-if="sortCode == 'cycleCodeUp'">
                <div class="sort-icon">
                  <img
                    :src="`${images}/icon/VectorupperInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    :src="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('cycleCodeDown'), changeSort('order_by', 'cycle_code', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
              <div v-else>
                <div class="uk-margin-small-bottom">
                  <div class="sort-icon">
                    <img
                      :src="`${images}/icon/Vectorupper.svg`"
                      alt=""
                      class="img-click"
                      @click="sortSwitch('cycleCodeUp'), changeSort('order_by', 'cycle_code', 'sort_by', 'ASC')"
                    >
                  </div>
                  <div class="sort-icon-down">
                    <img
                      :src="`${images}/icon/Vectordown.svg`"
                      alt=""
                      class="img-click"
                      @click="sortSwitch('cycleCodeDown'), changeSort('order_by', 'cycle_code', 'sort_by', 'DESC')"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="uk-table-expand">
        <div class="uk-margin-small-bottom">
          <span class="uk-text-bold">Nama Kandang</span>
        </div>
      </th>
      <th class="uk-table-expand">
        <div class="uk-margin-small-bottom">
          <span class="uk-text-bold">Anak Kandang</span>
        </div>
      </th>
      <th class="uk-width-small">
        <span class="uk-text-bold">Pengawas Lapangan</span>
      </th>
      <th class="uk-table-shrink">
        <span class="uk-text-bold">Siklus ke</span>
      </th>
      <th class="uk-table-expand">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-text-bold uk-width-expand uk-margin-small-right">Tahapan</span>
          <div class="uk-float-right uk-flex-column uk-width-auto">
            <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
              <div v-if="sortCode == 'currentLogActDown'">
                <div class="sort-icon">
                  <img
                    :src="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('currentLogActUp'), changeSort('order_by', 'current_log_activity', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    :src="`${images}/icon/VectordownInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
              </div>
              <div v-else-if="sortCode == 'currentLogActUp'">
                <div class="sort-icon">
                  <img
                    :src="`${images}/icon/VectorupperInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    :src="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('currentLogActDown'), changeSort('order_by', 'current_log_activity', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
              <div v-else>
                <div class="uk-margin-small-bottom">
                  <div class="sort-icon">
                    <img
                      :src="`${images}/icon/Vectorupper.svg`"
                      alt=""
                      class="img-click"
                      @click="sortSwitch('currentLogActUp'), changeSort('order_by', 'current_log_activity', 'sort_by', 'ASC')"
                    >
                  </div>
                  <div class="sort-icon-down">
                    <img
                      :src="`${images}/icon/Vectordown.svg`"
                      alt=""
                      class="img-click"
                      @click="sortSwitch('currentLogActDown'), changeSort('order_by', 'current_log_activity', 'sort_by', 'DESC')"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="uk-table-expand">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-text-bold uk-width-expand uk-margin-small-right">Tanggal Chick In</span>
          <div class="uk-float-right uk-flex-column uk-width-auto">
            <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
              <div v-if="sortCode == 'chickDown'">
                <div class="sort-icon">
                  <img
                    :src="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('chickUp'), changeSort('order_by', 'chick_in', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    :src="`${images}/icon/VectordownInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
              </div>
              <div v-else-if="sortCode == 'chickUp'">
                <div class="sort-icon">
                  <img
                    :src="`${images}/icon/VectorupperInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    :src="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('chickDown'), changeSort('order_by', 'chick_in', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
              <div v-else>
                <div class="uk-margin-small-bottom">
                  <div class="sort-icon">
                    <img
                      :src="`${images}/icon/Vectorupper.svg`"
                      alt=""
                      class="img-click"
                      @click="sortSwitch('chickUp'), changeSort('order_by', 'chick_in', 'sort_by', 'ASC')"
                    >
                  </div>
                  <div class="sort-icon-down">
                    <img
                      :src="`${images}/icon/Vectordown.svg`"
                      alt=""
                      class="img-click"
                      @click="sortSwitch('chickDown'), changeSort('order_by', 'chick_in', 'sort_by', 'DESC')"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="uk-width-small">
        <div class="uk-margin-small-bottom">
          <span class="uk-text-bold">Populasi (ekor)</span>
        </div>
      </th>
      <th class="uk-table-expand">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-text-bold uk-width-expand">Tanggal Terdaftar</span>
          <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
            <div v-if="sortCode == 'createUp'">
              <div class="sort-icon">
                <img
                  v-lazy="`${images}/icon/Vectorupper.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('createDown'), changeSort('order_by', 'created_at', 'sort_by', 'ASC')"
                >
              </div>
              <div class="sort-icon-down">
                <img
                  v-lazy="`${images}/icon/VectordownInnactive.svg`"
                  alt=""
                  class="img-click"
                >
              </div>
            </div>
            <div v-else-if="sortCode == 'createDown'">
              <div class="sort-icon">
                <img
                  v-lazy="`${images}/icon/VectorupperInnactive.svg`"
                  alt=""
                  class="img-click"
                >
              </div>
              <div class="sort-icon-down">
                <img
                  v-lazy="`${images}/icon/Vectordown.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('createUp'), changeSort('order_by', 'created_at', 'sort_by', 'DESC')"
                >
              </div>
            </div>
            <div v-else>
              <div class="sort-icon">
                <img
                  v-lazy="`${images}/icon/Vectorupper.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('createDown'), changeSort('order_by', 'created_at', 'sort_by', 'ASC')"
                >
              </div>
              <div class="sort-icon-down">
                <img
                  v-lazy="`${images}/icon/Vectordown.svg`"
                  alt=""
                  class="img-click"
                  @click="sortSwitch('createUp'), changeSort('order_by', 'created_at', 'sort_by', 'DESC')"
                >
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="uk-table-expand uk-width-small">
        <div class="uk-flex uk-flex-middle">
          <span class="uk-text-bold uk-width-expand">Terakhir Diubah</span>
          <div class="uk-float-right uk-flex-column uk-width-auto">
            <div class="uk-float-right uk-flex-column uk-width-auto uk-margin-small-bottom">
              <div v-if="sortCode == 'updateDown'">
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('updateUp'), changeSort('order_by', 'updated_at', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/VectordownInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
              </div>
              <div v-else-if="sortCode == 'updateUp'">
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/VectorupperInnactive.svg`"
                    alt=""
                    class="img-click"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('updateDown'), changeSort('order_by', 'updated_at', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
              <div v-else>
                <div class="sort-icon">
                  <img
                    v-lazy="`${images}/icon/Vectorupper.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('updateUp'), changeSort('order_by', 'updated_at', 'sort_by', 'ASC')"
                  >
                </div>
                <div class="sort-icon-down">
                  <img
                    v-lazy="`${images}/icon/Vectordown.svg`"
                    alt=""
                    class="img-click"
                    @click="sortSwitch('updateDown'), changeSort('order_by', 'updated_at', 'sort_by', 'DESC')"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </th>
      <th class="uk-table-small">
        <div class="uk-margin-small-bottom">
          <span class="uk-text-bold">Deplesi</span>
        </div>
      </th>
      <th class="uk-table-small">
        <span class="uk-text-bold">Bobot Sampel (gr)</span>
      </th>
      <th class="uk-table-small">
        <span class="uk-text-bold">Stok Pakan (sak)</span>
      </th>
      <th class="uk-table-expand uk-text-center">
        <div class="uk-margin-small-bottom">
          <span class="uk-text-bold">Aksi</span>
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import { PREFIX_IMAGE } from "@/utils/constant"

export default {
  data() {
    return {
      images: PREFIX_IMAGE,
      sortCode: "updateDown"
    }
  },
  computed: {
    ...mapGetters({
      meta: "activityCage/meta"
    })
  },
  methods: {
    ...mapMutations({
      setMeta: "activityCage/SET_META"
    }),
    changeSort(field, fieldValue, sortDirection, sortDirectionValue) {
      this.setMeta({
        ...this.meta,
        [field]: fieldValue,
        [sortDirection]: sortDirectionValue
      })
    },
    sortSwitch(a) {
      this.sortCode = a
    }
  }
}
</script>
